<template>
  <div class="top">
    <div class="userinfo" v-if="user">
      <a href="/user"><img src="~@/assets/images/noavatar.png" /></a
      ><span>35359769@qq</span>
    </div>
    <div class="logo" v-else>
      <a href="/"></a>
    </div>
    <div class="search">
      <!-- <form
        action="/"
        method="get"
        onsubmit="window.location.href='/search/index/keyword/'+document.getElementById('searchword').value;return false;"
      > -->
      <div class="search_bg">
        <div class="text">
          <input
            id="searchword"
            name="keyword"
            type="search"
            value="戳爆三国"
            onfocus="if(value==defaultValue){value='';this.style.color='#000'}"
            onblur="if(!value){value=defaultValue;this.style.color='#979797'}"
          />
        </div>
        <div class="search_btn">
          <div class="img_box">
            <button type="submit"></button>
          </div>
        </div>
      </div>
      <!-- </form> -->
    </div>

    <div class="subscribe">
      <i class="icon_mobile"></i>
      <a class="concern" v-on:click="showSubscribe">关注</a>
    </div>
    <!-- <div class="download">
      <i class="icon_mobile"></i>
     <a href="javascript:installapp();">安装APP</a>
    </div> -->
    <div id="showRightTopQrCodeMask" v-show="ok"></div>
    <div id="showRightTopQrCode" v-show="ok">
      <div class="relative">
        <img src="~@/assets/images/sublogo.png" />
        <div class="close" v-on:click="closeSubscribe">
          <img src="~@/assets/images/subclose.png" />
        </div>
      </div>
      <div class="qcode">
        <b>关注我们</b>
        <img src="~@/assets/images/qrcode.jpg" style="width: 60%" />
        <span>微信扫一扫二维码</span>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "top",
  props: {
    // showlogo:{
    //   type:Boolean
    // },
    // showuserimg:{
    //   type:Boolean
    // }
  },
  data() {
    return {
      ok: false,
      user:'',
    };
  },
  computed: {
    // ...mapState(["user"]),
  },
  methods: {
    showSubscribe: function () {
      this.ok = true;
    },
    closeSubscribe: function () {
      this.ok = false;
    },
  },
  created() {
    this.user = this.$store.state.user;
    const route = useRoute();
    // let path = route.path;
    // console.log(path);
    if (route.path == "/user") {
      this.user=''
    }

  },
  setup() {


    // console.log(this.user);
    return {
      //path: path,
    };
  },
};
</script>

<style>
.top {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 76px;
  background: #0896d8;
  display: flex;
  z-index: 999;
  justify-content: space-between;
}
.top .logo {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top .userinfo {
  width: 20%;
  height: 100%;
  line-height: 56px;
  color: #fff;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
}
.top .logo a {
  display: block;
  margin: 4px auto;
  width: 230px;
  height: 60px;
  background: url("~@/assets/images/logo.png");
  background-size: 100% 100%;
}
.top .userinfo img {
  width: 94px;
  height: 94px;
  margin: 0px 15px 0 30px;
  border-radius: 50%;
  border: 1px solid #0896d8;
}
.top .userinfo span {
  display: inline-block;
  color: #ffffff;
  line-height: 75px;
}
.top .search {
  width: 234px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.top .search .search_bg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 50px;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
}
.top .search .search_bg .text {
  margin-left: 12px;
  width: 75%;
  height: 100%;
}
.top .search .search_bg .text input {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #979797;
  outline: none;
  background: #ffffff;
  vertical-align: text-top;
}
.top .search .search_bg .search_btn {
  width: 20%;
  height: 100%;
}
.top .search .search_bg .search_btn .img_box {
  float: right;
}
.top .search .search_bg .search_btn .img_box button {
  background: url(~@/assets/images/ss.jpg);
  background-size: 100% 100%;
  width: 35px;
  height: 35px;
  display: block;
  margin-top: 4px;
  margin-right: 10px;
}
.top .top_box {
  position: relative;
  margin: 0 auto;
  width: 94%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.top .top_box .back {
  margin: 0;
  width: 60px;
  height: 46px;
  display: flex;
}
.top .top_box .back img {
  height: 18px;
  margin-top: 13px;
}
.top .top_box .back span {
  line-height: 46px;
  height: 46px;
  font-size: 15px;
  color: #ffffff;
  vertical-align: middle;
}
.top .top_box .back .text_ri {
  display: block;
  width: 100%;
  text-align: right;
}
.top .top_box .search2 {
  width: auto;
  flex-grow: 1;
}
.top .top_box .search2 .search_bg {
  margin: 0 0 0 10%;
}
.top .top_box .search2 .search_bg input {
  color: #ffffff;
}
.top .top_box .top_title {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top .top_box .top_title span {
  display: block;
  color: #ffffff;
  font-size: 20px;
}

.top .download {
  /* width: 20%; */
  height: 100%;
  text-align: center;
}
.top .subscribe .icon_mobile {
  font-size: 42px;
  animation: icon_mobile_bounce 1s infinite linear;
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  color: #ffffff;
  height: 42px;
  line-height: 76px;
}

.top .subscribe .icon_mobile:before {
  content: "\e601";
}

.top .subscribe {
  height: 100%;
  padding: 0px 15px 0 15px;
}

.top .subscribe a {
  height: 100%;
  line-height: 76px;
  text-decoration: none;
  font-size: 24px;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}
.top .download {
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.top .download .icon-mobile {
  font-size: 1.2rem;
  animation: icon-mobile-bounce 1s infinite linear;
  display: inline-block;
  font-family: "webfont";
  font-style: normal;
  color: #ffffff;
  height: 1.2rem;
}

@media only screen and (max-width: 426px) {
  .top .search .search_bg {
    width: 90%;
  }
  .top .userinfo span {
    display: none;
  }
  .top .search .search_bg .text input {
    font-size: 24px;
  }
}

@keyframes icon_mobile_bounce {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(5deg);
  }
  85% {
    transform: rotate(-5deg);
  }
}
</style>