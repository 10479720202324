<template>
  <router-view />
  <ul id="menu">
    <a href="/">
      <li class="home" v-bind:class="path == '/' ? 'on' : ''">
        <em></em><span>首页</span>
      </li>
    </a>
    <a href="/gifts">
      <li class="gifts" v-bind:class="path == '/gifts' ? 'on' : ''">
        <em></em><span>礼包</span>
      </li>
    </a>
    <a href="/type">
      <li class="type" v-bind:class="path == '/type' ? 'on' : ''">
        <em></em><span>分类</span>
      </li>
    </a>
    <a href="/user">
      <li class="player" v-bind:class="path == '/user' ? 'on' : ''">
        <em></em><span>我的</span>
      </li>
    </a>
  </ul>
</template>

<script>
import { useRoute } from "vue-router";
export default {
  name: "fnav",
  components: {
  },
  props: {},
  data() {
    return {
      path:"",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
  setup() {
    const route = useRoute();
    let path = route.path;
    return {
      path: path,
    };
  },
};
</script>

<style lang="scss">
#menu {
  position: fixed;
  width: 100%;
  max-width: 750px;
  background: #323232;
  bottom: 0px;
  z-index: 99999;
}
#menu li {
  float: left;
  width: 25%;
  line-height: 80px;
  text-align: center;
  font-size: 24px;
  background-repeat: no-repeat;
}
#menu li.on span {
  color: #0896d8 !important;
}
#menu li em {
  display: block;
  margin: 5px auto;
  width: 48px;
  height: 48px;
  background-size: 48px 48px;
}
#menu li.home em {
  background-image: url("~@/assets/images/fnav_1.png");
}
#menu li.home.on em {
  background-image: url("~@/assets/images/fnav_1h.png");
}

#menu li.type em {
  background-image: url("~@/assets/images/fnav_2.png");
}
#menu li.type.on em {
  background-image: url("~@/assets/images/fnav_2h.png");
}

#menu li.gifts em {
  background-image: url("~@/assets/images/fnav_3.png");
}
#menu li.gifts.on em {
  background-image: url("~@/assets/images/fnav_3h.png");
}

#menu li.player em {
  background-image: url("~@/assets/images/fnav_4.png");
}
#menu li.player.on em {
  background-image: url("~@/assets/images/fnav_4h.png");
}

#menu li span {
  display: block;
  width: 100%;
  color: #979797;
  line-height: 36px;
  text-align: center;
  font-size: 24px;
  padding-bottom: 0;
}
</style>